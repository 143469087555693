/*
import { auth, db, FirebaseFieldValue } from "../config/main_config";

const signOut = async function () {
  const usersRef = db.collection("users").doc(auth.currentUser.uid);
  await usersRef.get().then(async (doc) => {
    if (doc.exists) {
      if (doc.data().roles.msp == true && doc.data().mspImpersonation == true) {
        await usersRef.update({
          organisations: FirebaseFieldValue.arrayRemove(doc.data().organisations[1]),
          mspImpersonation: false,
        }).then(() => {
          window.location = "msp-view.html";
        });
      } else {
        if (doc.data().organisations.length > 1) {
          await usersRef.update({
            organisations: FirebaseFieldValue.arrayRemove(doc.data().organisations[1]),
            mspImpersonation: false,
          }).then(() => {
            window.location = "msp-view.html";
          });
        }
        auth.signOut().then(() => {
          window.location = "login.html";
        }).catch(error => {// An error happened.
        });
      }
    }
  }).catch((e) => {
    console.error(e);
    auth.signOut().then(() => {
      window.location = "login.html";
    }).catch(error => {// An error happened.
    });
  })
}

const forceSignOut = async function () { // This function is used to force the user to sign out and redirect to the login page. Called from public/src/js/external-js/variableModules/standardOnLoadFunction.module.js:31
  const usersRef = db.collection("users").doc(auth.currentUser.uid);
  const usersDoc = await usersRef.get();
  if (usersDoc.data().organisations.length > 1) {
    await usersRef.update({
      organisations: FirebaseFieldValue.arrayRemove(usersDoc.data().organisations.find(org => org !== window.companyID)),
      mspImpersonation: false,
    }).then(() => {
      window.location = "msp-view.html";
      return;
    });
  }
  auth.signOut().then(() => {
    window.location = "login.html";
    return;
  }).catch(error => {
    console.log(error);
  });
}

window.signOut = signOut;
window.forceSignOut = forceSignOut;

export { signOut, forceSignOut };
*/

import { auth, db, FirebaseFieldValue } from "../config/main_config";

// Helper function to clear MSP impersonation
const clearMspImpersonation = async (usersRef, userData) => {
  console.log('userData.organisations:', userData.organisations);
  console.log('userData.mspOrgs:', userData.mspOrgs);
  if (userData.organisations?.length > 1 && userData.mspImpersonation) {
    const orgToNotRemove = userData.organisations.find(org =>
      !userData.mspOrgs.includes(org)
    );
    console.log('orgToNotRemove:', orgToNotRemove);
    if (orgToNotRemove) {
      const orgToRemove = userData.organisations.find(org =>
        org !== orgToNotRemove
      );
      console.log('orgToRemove:', orgToRemove);
      if (orgToRemove) {
        await usersRef.update({
          organisations: FirebaseFieldValue.arrayRemove(orgToRemove),
          mspImpersonation: false,
        });
      }
    }
  }
};

// Helper function to perform the actual sign out
const performSignOut = async (redirectUrl) => {
  try {
    await auth.signOut();
    document.cookie = "__session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location = redirectUrl;
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
};

// Main sign out function
const signOut = async function () {
  try {
    const usersRef = db.collection("users").doc(auth.currentUser.uid);
    const doc = await usersRef.get();
    if (!doc.exists) {
      await performSignOut("login.html");
      return;
    }
    const userData = doc.data();
    await clearMspImpersonation(usersRef, userData);
    if (userData.roles.msp && userData.mspImpersonation) {
      await performSignOut("msp-view.html");
      return;
    }
    await performSignOut("login.html");
  } catch (error) {
    console.error('Error during sign out:', error);
    await performSignOut("login.html");
  }
};

// Force sign out function
const forceSignOut = async function (onboarding = false) {
  try {
    const usersRef = db.collection("users").doc(auth.currentUser.uid);
    const doc = await usersRef.get();
    const userData = doc.data();
    await clearMspImpersonation(usersRef, userData);
    await performSignOut(onboarding ? `${onboarding}.html` : "login.html");
  } catch (error) {
    console.error('Error during forced sign out:', error);
    await performSignOut("login.html");
  }
};

window.signOut = signOut;
window.forceSignOut = forceSignOut;

export { signOut, forceSignOut };