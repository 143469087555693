// export default class ComponentLoader {
//     constructor() {
//         this.components = import.meta.glob('../../../components/*.component', {
//             eager: false // Lazy-load the components as needed
//         });

//         console.log(this.components);

//         // Load global functions (like router additives)
//         this.globalFunctionsLoaded = import('./utils/routerAdditives.util.ts').then((module) => {
//             // Attach functions to the window object to make them globally accessible
//             Object.assign(window, module);
//         });

//         // Initialize the custom elements when the class is instantiated
//         this.loadedElements = new Set(); // Track loaded elements globally
//         this.loadAllComponents();
//     }

//     // Main function to load custom elements
//     async loadCustomElements(parentElement) {
//         // If this element has already been loaded, return early to prevent reloading
//         if (this.loadedElements.has(parentElement)) {
//             return;
//         }

//         // Mark this element as loaded
//         this.loadedElements.add(parentElement);

//         // Get all available component tag names
//         const componentNames = Object.keys(this.components).map((filePath) => {
//             // Extract the component's base file name as the tag name (e.g. 'registerTable')
//             return filePath.match(/\/([^\/]+)\.component$/)[1].toLowerCase();
//         });

//         console.log('Components to load:', componentNames);

//         // First, load the parent component (the custom element itself)
//         const parentTagName = parentElement.tagName.toLowerCase();
//         try {
//             await this.globalFunctionsLoaded; // Wait for global functions to be loaded
//             const parentComponent = await this.importComponent(parentTagName); // Dynamically import the parent component
//             await this.insertComponentContent(parentElement, parentComponent); // Insert the parent component content
//             console.log(`Loaded parent component: ${parentTagName}`);
//         } catch (error) {
//             console.error(`Error loading parent component ${parentTagName}:`, error);
//         }

//         // Query for any custom elements inside the parent component that match the component names
//         const customElements = componentNames.flatMap((name) => Array.from(parentElement.querySelectorAll(name.toLowerCase())));
//         console.log('Custom elements found:', customElements);

//         // Now, load nested custom elements inside the parent component (recursively if needed)
//         for (const customElement of customElements) {
//             const tagName = customElement.tagName.toLowerCase();
//             try {
//                 await this.globalFunctionsLoaded; // Wait for global functions to be loaded
//                 // Dynamically import the corresponding component when the tag is found
//                 const component = await this.importComponent(tagName);
//                 console.log(`Found and loading nested component: ${tagName}`);

//                 // Manage and insert the component content into the custom element
//                 await this.insertComponentContent(customElement, component);

//                 // Recursively load custom elements inside this customElement if needed
//                 await this.loadCustomElements(customElement);
//             } catch (error) {
//                 console.error(`Error loading nested component ${tagName}: ${error.message}`);
//             }
//         }
//     }

//     // Import the component based on the tagName
//     async importComponent(tagName) {
//         // Find the component file based on the tagName (which is the component's base name)
//         const componentPath = Object.keys(this.components).find((filePath) => {
//             console.log(`Checking file path: ${filePath}`);  // Debugging: Log file paths
//             return filePath.toLowerCase().includes(`${tagName}.component`);
//         });

//         if (!componentPath) {
//             throw new Error(`Component ${tagName} not found`);
//         }

//         console.log(`Loading component from path: ${componentPath}`);  // Debugging: Log the component path

//         // Dynamically import the component module
//         const componentModule = await this.components[componentPath]();

//         console.log(componentModule);  // Debugging: Check the imported module

//         // Extract the HTML, SCSS/SASS/CSS, and JS from the module
//         const { html, scss, sass, css, js, target } = componentModule;

//         // Handle SCSS, SASS, and CSS
//         let compiledCSS = css; // Default to css if no scss or sass is found
//         if (scss || sass) {
//             compiledCSS = await this.compileSCSS(scss || sass); // Compile SCSS/SASS if present
//         }

//         return { html, css: compiledCSS, js, target };
//     }

//     // Function to compile SCSS/SASS to CSS
//     async compileSCSS(scss) {
//         return new Promise((resolve, reject) => {
//             try {

//                 // Dynamically import the 'sass' library for SCSS compilation
//                 import('sass').then(sass => {
//                     try {
//                         // Compile the SCSS string to CSS using the sass.compileString method
//                         const result = sass.compileString(scss);

//                         // Check if there are any errors in the compilation result
//                         if (result.hasOwnProperty('css')) {
//                             resolve(result.css); // Return compiled CSS if successful
//                         } else {
//                             reject(`Error compiling SCSS: `, result); // If there's an error in the result, reject with the formatted error message
//                         }
//                     } catch (sassError) {
//                         reject(`Error during SCSS compilation:`, sassError); // Catch any errors from the 'sass' library
//                     }
//                 }).catch(importError => {
//                     reject(`Error importing sass library:`, importError); // Handle errors from the import statement
//                 });
//             } catch (error) {
//                 reject(`Error compiling SCSS:`, error); // Catch any unexpected errors
//             }
//         });
//     }
//     // Insert the HTML, CSS, and JS of the component into the custom element
//     async insertComponentContent(customElement, component) {
//         const existingContent = customElement.innerHTML;
//         const ManagedID = `ComponentLoader_Managed_${Math.random().toString(36).substr(2, 9)}`;

//         customElement.innerHTML = component.html;
//         customElement.id = ManagedID;

//         // Insert the component's CSS content, if present
//         if (component.css) {
//             const styleEl = document.createElement('style');
//             styleEl.textContent = component.css;
//             document.head.appendChild(styleEl);
//         }

//         // Execute the component's JavaScript, if present
//         if (component.js) {
//             const scriptEl = document.createElement('script');
//             scriptEl.type = 'module';
//             scriptEl.textContent = `
//               (async function () {
//                 var ComponentLoaderCustomElementID = '${customElement.id}';
//                 ${component.js}
//               })()
//             `;
//             document.body.appendChild(scriptEl);
//         }

//         // Append the saved existing content after inserting the component's HTML
//         if (existingContent.trim()) {
//             const targetElement = document.querySelector(`#${ManagedID} ${component.target}`);
//             if (targetElement) {
//                 targetElement.innerHTML += existingContent;
//             }
//         }

//         // **Process nested custom elements**
//         await this.loadCustomElements(customElement);
//     }

//     // Function to load all components present in the document
//     async loadAllComponents() {
//         // Load components for all elements in the document
//         const elementsWithCustomTags = Array.from(document.querySelectorAll('*')).filter((el) => {
//             return Object.keys(this.components).some((filePath) => {
//                 const tagName = filePath.match(/\/([^\/]+)\.component$/)[1].toLowerCase();
//                 return el.tagName.toLowerCase() === tagName;
//             });
//         });

//         console.log('Elements with custom tags:', elementsWithCustomTags);

//         for (const element of elementsWithCustomTags) {
//             await this.loadCustomElements(element);
//         }
//     }
// }


// Almost
// export default class ComponentLoader {
//     constructor() {
//         this.components = import.meta.glob('../../../components/*.component', {
//             eager: false // Lazy-load the components as needed
//         });

//         console.log(this.components);

//         // Load global functions (like router additives)
//         this.globalFunctionsLoaded = import('./utils/routerAdditives.util.ts').then((module) => {
//             // Attach functions to the window object to make them globally accessible
//             Object.assign(window, module);
//         });

//         // Initialize the custom elements when the class is instantiated
//         this.loadedElements = new Set(); // Track loaded elements globally
//         this.loadAllComponents();

//         // Set up MutationObserver to detect new custom elements
//         setTimeout(() => {
//             this.loadAllComponents();
//         }, 3000)
//     }

//     // Set up MutationObserver to watch for DOM changes
//     setupMutationObserver() {
//         const observer = new MutationObserver((mutations) => {
//             mutations.forEach((mutation) => {
//                 if (mutation.type === 'childList') {
//                     mutation.addedNodes.forEach((node) => {
//                         if (node.nodeType === 1) { // Check if it's an element node
//                             // Only load custom elements, not generic ones like <div>, <iframe>, etc.
//                             this.loadCustomElements(node);
//                         }
//                     });
//                 }
//             });
//         });

//         // Observe the entire document for added nodes
//         observer.observe(document.body, {
//             childList: true,
//             subtree: true,
//         });

//         setTimeout(() => {
//             console.log('Mutation observer setup complete');
//             observer.disconnect();
//         }, 3000)
//     }

//     // Main function to load custom elements
//     async loadCustomElements(parentElement) {
//         // If this element has already been loaded, return early to prevent reloading
//         if (this.loadedElements.has(parentElement)) {
//             return;
//         }

//         // Get all available component tag names
//         const componentNames = Object.keys(this.components).map((filePath) => {
//             // Extract the component's base file name as the tag name (e.g. 'registerTable')
//             return filePath.match(/\/([^\/]+)\.component$/)[1].toLowerCase();
//         });

//         console.log('Components to load:', componentNames);

//         // Only load components for elements that match the registered component tag names
//         const parentTagName = parentElement.tagName.toLowerCase();
//         if (!componentNames.includes(parentTagName)) {
//             // This is not a registered custom component, so return early
//             return;
//         }

//         // If it's a custom component, load it
//         try {
//             await this.globalFunctionsLoaded; // Wait for global functions to be loaded
//             const parentComponent = await this.importComponent(parentTagName); // Dynamically import the parent component
//             await this.insertComponentContent(parentElement, parentComponent); // Insert the parent component content
//             console.log(`Loaded parent component: ${parentTagName}`);
//         } catch (error) {
//             console.error(`Error loading parent component ${parentTagName}:`, error);
//         }

//         // Query for any custom elements inside the parent component that match the component names
//         const customElements = componentNames.flatMap((name) => Array.from(parentElement.querySelectorAll(name.toLowerCase())));
//         console.log('Custom elements found:', customElements);

//         // Now, load nested custom elements inside the parent component (recursively if needed)
//         for (const customElement of customElements) {
//             const tagName = customElement.tagName.toLowerCase();
//             try {
//                 await this.globalFunctionsLoaded; // Wait for global functions to be loaded
//                 // Dynamically import the corresponding component when the tag is found
//                 const component = await this.importComponent(tagName);
//                 console.log(`Found and loading nested component: ${tagName}`);

//                 // Manage and insert the component content into the custom element
//                 await this.insertComponentContent(customElement, component);

//                 // Recursively load custom elements inside this customElement if needed
//                 await this.loadCustomElements(customElement);
//             } catch (error) {
//                 console.error(`Error loading nested component ${tagName}: ${error.message}`);
//             }
//         }
//     }

//     // Import the component based on the tagName
//     async importComponent(tagName) {
//         // Find the component file based on the tagName (which is the component's base name)
//         const componentPath = Object.keys(this.components).find((filePath) => {
//             console.log(`Checking file path: ${filePath}`);  // Debugging: Log file paths
//             return filePath.toLowerCase().includes(`${tagName}.component`);
//         });

//         if (!componentPath) {
//             throw new Error(`Component ${tagName} not found`);
//         }

//         console.log(`Loading component from path: ${componentPath}`);  // Debugging: Log the component path

//         // Dynamically import the component module
//         const componentModule = await this.components[componentPath]();

//         console.log(componentModule);  // Debugging: Check the imported module

//         // Extract the HTML, SCSS/SASS/CSS, and JS from the module
//         const { html, scss, sass, css, js, target } = componentModule;

//         // Handle SCSS, SASS, and CSS
//         let compiledCSS = css; // Default to css if no scss or sass is found
//         if (scss || sass) {
//             compiledCSS = await this.compileSCSS(scss || sass); // Compile SCSS/SASS if present
//         }

//         return { html, css: compiledCSS, js, target };
//     }

//     // Function to compile SCSS/SASS to CSS
//     async compileSCSS(scss) {
//         return new Promise((resolve, reject) => {
//             try {
//                 // Dynamically import the 'sass' library for SCSS compilation
//                 import('sass').then(sass => {
//                     try {
//                         // Compile the SCSS string to CSS using the sass.compileString method
//                         const result = sass.compileString(scss);

//                         // Check if there are any errors in the compilation result
//                         if (result.hasOwnProperty('css')) {
//                             resolve(result.css); // Return compiled CSS if successful
//                         } else {
//                             reject(`Error compiling SCSS: `, result); // If there's an error in the result, reject with the formatted error message
//                         }
//                     } catch (sassError) {
//                         reject(`Error during SCSS compilation:`, sassError); // Catch any errors from the 'sass' library
//                     }
//                 }).catch(importError => {
//                     reject(`Error importing sass library:`, importError); // Handle errors from the import statement
//                 });
//             } catch (error) {
//                 reject(`Error compiling SCSS:`, error); // Catch any unexpected errors
//             }
//         });
//     }

//     // Insert the HTML, CSS, and JS of the component into the custom element
//     async insertComponentContent(customElement, component) {
//         const existingContent = customElement.innerHTML;
//         const ManagedID = `ComponentLoader_Managed_${Math.random().toString(36).substr(2, 9)}`;

//         customElement.innerHTML = component.html;
//         customElement.id = ManagedID;

//         // Insert the component's CSS content, if present
//         if (component.css) {
//             const styleEl = document.createElement('style');
//             styleEl.textContent = component.css;
//             document.head.appendChild(styleEl);
//         }

//         // Execute the component's JavaScript, if present
//         if (component.js) {
//             const scriptEl = document.createElement('script');
//             scriptEl.type = 'module';
//             scriptEl.textContent = `
//               (async function () {
//                 var ComponentLoaderCustomElementID = '${customElement.id}';
//                 ${component.js}
//               })()
//             `;
//             document.body.appendChild(scriptEl);
//         }

//         // Append the saved existing content after inserting the component's HTML
//         if (existingContent.trim()) {
//             const targetElement = document.querySelector(`#${ManagedID} ${component.target}`);
//             if (targetElement) {
//                 targetElement.innerHTML += existingContent;
//             }
//         }

//         // **Process nested custom elements**
//         await this.loadCustomElements(customElement);
//     }

//     // Function to load all components present in the document
//     async loadAllComponents() {
//         // Load components for all elements in the document
//         const elementsWithCustomTags = Array.from(document.querySelectorAll('*')).filter((el) => {
//             return Object.keys(this.components).some((filePath) => {
//                 const tagName = filePath.match(/\/([^\/]+)\.component$/)[1].toLowerCase();
//                 return el.tagName.toLowerCase() === tagName;
//             });
//         });

//         console.log('Elements with custom tags:', elementsWithCustomTags);

//         for (const element of elementsWithCustomTags) {
//             await this.loadCustomElements(element);
//         }
//     }
// }


export default class ComponentLoader {
    constructor() {
        // Don't initalise if managementSystemGuide is in url 
        if (window.location.href.includes('/managementSystemGuide')) {
            return;
        }

        this.components = import.meta.glob('../../../components/*.component', {
            eager: false // Lazy-load the components as needed
        });

        // console.log(this.components);

        // Load global functions (like router additives)
        this.globalFunctionsLoaded = import('./utils/routerAdditives.util.ts').then((module) => {
            // Attach functions to the window object to make them globally accessible
            Object.assign(window, module);
        });

        // Initialize the custom elements when the class is instantiated
        this.loadedElements = new Set(); // Track loaded elements globally
        this.loadAllComponents();

        // Set up MutationObserver to detect new custom elements
        this.setupMutationObserver();

        // Set up the initial check delay and periodic check
        this.setupDelayedCheck();
    }

    // Set up MutationObserver to watch for DOM changes (only initially for 3 seconds)
    setupMutationObserver() {
        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((node) => {
                        if (node.nodeType === 1) { // Check if it's an element node
                            // Only load custom elements, not generic ones like <div>, <iframe>, etc.
                            this.loadCustomElements(node);
                        }
                    });
                }
            });
        });

        // Observe the entire document for added nodes
        this.observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    }

    // Function to set up the delayed initial check and periodic checks
    setupDelayedCheck() {
        // Initial check after 3 seconds
        setTimeout(() => {
            // console.log('Initial custom elements check');
            this.loadAllComponents();

            // After the first check, stop the MutationObserver to prevent constant checks
            this.stopMutationObserver();

            // After stopping the observer, start checking every 10 seconds
            this.startPeriodicCheck();
        }, 1000); // Delay the initial check by 3 seconds
    }

    // Start periodic checks every 10 seconds
    startPeriodicCheck() {
        this.periodicCheckInterval = setInterval(() => {
            // console.log('Periodic custom elements check');
            this.loadAllComponents();
        }, 3000); // Check every 10 seconds
    }

    // Stop MutationObserver after the first check
    stopMutationObserver() {
        if (this.observer) {
            this.observer.disconnect();  // Disconnect the observer to stop it
            // console.log('MutationObserver stopped');
        }
    }

    // Stop periodic checks when needed (e.g., cleanup on component destroy)
    stopPeriodicCheck() {
        if (this.periodicCheckInterval) {
            clearInterval(this.periodicCheckInterval);
            // console.log('Periodic check stopped');
        }
    }

    // Main function to load custom elements
    async loadCustomElements(parentElement) {
        // If this element has already been loaded, return early to prevent reloading
        if (this.loadedElements.has(parentElement)) {
            // console.log(`Skipping already loaded element: ${parentElement.tagName}`);
            return;
        }

        // Mark this element as loaded
        this.loadedElements.add(parentElement);

        // Get all available component tag names
        const componentNames = Object.keys(this.components).map((filePath) => {
            // Extract the component's base file name as the tag name (e.g. 'registerTable')
            return filePath.match(/\/([^\/]+)\.component$/)[1].toLowerCase();
        });

        // console.log('Components to load:', componentNames);

        // Only load components for elements that match the registered component tag names
        const parentTagName = parentElement.tagName.toLowerCase();
        if (!componentNames.includes(parentTagName)) {
            // This is not a registered custom component, so return early
            return;
        }

        // If it's a custom component, load it
        try {
            await this.globalFunctionsLoaded; // Wait for global functions to be loaded
            const parentComponent = await this.importComponent(parentTagName); // Dynamically import the parent component
            await this.insertComponentContent(parentElement, parentComponent); // Insert the parent component content
            // console.log(`Loaded parent component: ${parentTagName}`);
        } catch (error) {
            console.error(`Error loading parent component ${parentTagName}:`, error);
        }

        // Query for any custom elements inside the parent component that match the component names
        const customElements = componentNames.flatMap((name) => Array.from(parentElement.querySelectorAll(name.toLowerCase())));
        // console.log('Custom elements found:', customElements);

        // Now, load nested custom elements inside the parent component (recursively if needed)
        for (const customElement of customElements) {
            const tagName = customElement.tagName.toLowerCase();
            try {
                await this.globalFunctionsLoaded; // Wait for global functions to be loaded
                // Dynamically import the corresponding component when the tag is found
                const component = await this.importComponent(tagName);
                // console.log(`Found and loading nested component: ${tagName}`);

                // Manage and insert the component content into the custom element
                await this.insertComponentContent(customElement, component);

                // Recursively load custom elements inside this customElement if needed
                await this.loadCustomElements(customElement);
            } catch (error) {
                console.error(`Error loading nested component ${tagName}: ${error.message}`);
            }
        }
    }

    // Import the component based on the tagName
    async importComponent(tagName) {
        // Find the component file based on the tagName (which is the component's base name)
        const componentPath = Object.keys(this.components).find((filePath) => {
            // console.log(`Checking file path: ${filePath}`);  // Debugging: Log file paths
            return filePath.toLowerCase().includes(`${tagName}.component`);
        });

        if (!componentPath) {
            throw new Error(`Component ${tagName} not found`);
        }

        // console.log(`Loading component from path: ${componentPath}`);  // Debugging: Log the component path

        // Dynamically import the component module
        const componentModule = await this.components[componentPath]();

        // console.log(componentModule);  // Debugging: Check the imported module

        // Extract the HTML, SCSS/SASS/CSS, and JS from the module
        const { html, scss, sass, css, js, target } = componentModule;

        // Handle SCSS, SASS, and CSS
        let compiledCSS = css; // Default to css if no scss or sass is found
        if (scss || sass) {
            compiledCSS = await this.compileSCSS(scss || sass); // Compile SCSS/SASS if present
        }

        return { html, css: compiledCSS, js, target };
    }

    // Function to compile SCSS/SASS to CSS
    async compileSCSS(scss) {
        return new Promise((resolve, reject) => {
            try {
                // Dynamically import the 'sass' library for SCSS compilation
                import('sass').then(sass => {
                    try {
                        // Compile the SCSS string to CSS using the sass.compileString method
                        const result = sass.compileString(scss);

                        // Check if there are any errors in the compilation result
                        if (result.hasOwnProperty('css')) {
                            resolve(result.css); // Return compiled CSS if successful
                        } else {
                            reject(`Error compiling SCSS: `, result); // If there's an error in the result, reject with the formatted error message
                        }
                    } catch (sassError) {
                        reject(`Error during SCSS compilation:`, sassError); // Catch any errors from the 'sass' library
                    }
                }).catch(importError => {
                    reject(`Error importing sass library:`, importError); // Handle errors from the import statement
                });
            } catch (error) {
                reject(`Error compiling SCSS:`, error); // Catch any unexpected errors
            }
        });
    }

    // Insert the HTML, CSS, and JS of the component into the custom element
    async insertComponentContent(customElement, component) {
        const existingContent = customElement.innerHTML;
        const ManagedID = `ComponentLoader_Managed_${Math.random().toString(36).substr(2, 9)}`;

        customElement.innerHTML = component.html;
        customElement.id = ManagedID;

        // Insert the component's CSS content, if present
        if (component.css) {
            const styleEl = document.createElement('style');
            styleEl.textContent = component.css;
            document.head.appendChild(styleEl);
        }

        // Execute the component's JavaScript, if present
        if (component.js) {
            const scriptEl = document.createElement('script');
            scriptEl.type = 'module';
            scriptEl.textContent = `
              (async function () {
                var ComponentLoaderCustomElementID, RouteHandlerCustomElementID = '${customElement.id}';
                ${component.js}
              })()
            `;
            document.body.appendChild(scriptEl);
        }

        // Append the saved existing content after inserting the component's HTML
        if (existingContent.trim()) {
            const targetElement = document.querySelector(`#${ManagedID} ${component.target}`);
            if (targetElement) {
                targetElement.innerHTML += existingContent;
            }
        }

        // **Process nested custom elements**
        await this.loadCustomElements(customElement);
    }

    // Function to load all components present in the document
    async loadAllComponents() {
        // Load components for all elements in the document
        const elementsWithCustomTags = Array.from(document.querySelectorAll('*')).filter((el) => {
            return Object.keys(this.components).some((filePath) => {
                const tagName = filePath.match(/\/([^\/]+)\.component$/)[1].toLowerCase();
                return el.tagName.toLowerCase() === tagName;
            });
        });

        // console.log('Elements with custom tags:', elementsWithCustomTags);

        for (const element of elementsWithCustomTags) {
            await this.loadCustomElements(element);
        }
    }
}